import React from 'react';
import { graphql } from 'gatsby';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import Tyr from '../src/components/Tyr/Tyr';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

import '../paw-pages/distributiva_entorno';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query destinyQuery($locale: String!, $zone_name: String!) {
    allDestinySeoData(filter: { lang: { eq: $locale }, tag: { eq: $zone_name } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allDestinyHeaderBlock(filter: { lang: { eq: $locale }, tag: { eq: $zone_name } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allDestinyImageHeaderBlock(filter: { lang: { eq: $locale }, tag: { eq: $zone_name } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDestinyFreya(filter: { lang: { eq: $locale }, tag: { eq: $zone_name } }) {
      edges {
        node {
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allDestinyDiscoverParksBlock(filter: { lang: { eq: $locale }, tag: { eq: $zone_name } }) {
      edges {
        node {
          title
          cta
          ctaText
          size
          color
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDestinyRichData(filter: { lang: { eq: $locale }, tag: { eq: $zone_name } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class FichaDestiny extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allDestinyHeaderBlock.edges[0].node.title,
      image: {
        url: this.props.data.allDestinyImageHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const prepareForTyr = (data) => ({
      cards: data.edges
        .filter((e) => e.node.title.length)
        .map((item) => ({
          MainImage: item.node.localImage.childImageSharp.fluid,
          altImage: item.node.image_alt,
          Title: item.node.title,
          TitleURL: item.node.cta,
          button1: {
            cta: item.node.cta,
            size: item.node.size,
            color: item.node.color,
            ctaText: item.node.ctaText,
          },
        })),
    });

    const h2Data = {
      title: this.props.data.allDestinyHeaderBlock.edges[0].node.title,
      description: this.props.data.allDestinyHeaderBlock.edges[0].node.description,
    };

    const freyaData = {
      title: tt('DESCUBRE LAS POBLACIONES CERCANAS', this.props.pageContext.locale),
      isSlider: true,
      cards: this.props.data.allDestinyFreya.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        title: item.node.title,
        description: item.node.description,
        imgsize: 'big',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allDestinySeoData.edges[0].node._0.title}
          description={this.props.data.allDestinySeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="entornos-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={heimdallData.name}
              />
              <Hod
                data={this.props.data.allDestinyRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allDestinyImageHeaderBlock.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <H2 data={h2Data} />
              <Tyr data={prepareForTyr(this.props.data.allDestinyDiscoverParksBlock)} />
              {this.props.data.allDestinyFreya.edges.length ? (
                <>
                  <H2 data={freyaData} />
                  <Freya data={freyaData} />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default FichaDestiny;
